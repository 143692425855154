<template>
  <section
    id="knowledge-base-content"
    class="px-4"
  >
    <b-row class="match-height">
      <b-col
        lg="3"
        cols="6"
      >
        Locations
      </b-col>

    </b-row>
    <!--/ Miscellaneous Charts -->

  </section>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BRow, BCol, BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,

  },

  setup() {
    return {

    }
  },
}

</script>
